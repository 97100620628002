import { jsPDF } from 'jspdf'
import autoTable from 'jspdf-autotable'
import { apiUri } from '../../utils/api'
import { format } from 'date-fns'

import store from '../../store'

export default function generatePDF (messages, ticket) {
  const ticketsIds = new Set()

  messages.forEach(message => {
    ticketsIds.add(message.ticket_id)
  })

  // eslint-disable-next-line new-cap
  const doc = new jsPDF({
    orientation: 'p',
    unit: 'mm',
    format: 'a4',
    putOnlyUsedFonts: true,
    floatPrecision: 16
  })

  let y = 10
  const x = 10

  doc.setFontSize(10)

  // Header rectangle
  doc.setFillColor(64, 224, 208)
  doc.rect(x, y, 190, 20, 'F')

  // logo
  doc.addImage(logoImg(), 'PNG', x + 5, y + 3, 40, 15)

  // Header text
  doc.setFontSize(15)
  doc.text('Relatório de mensagens enviadas', x + 85, y + 10)

  // Header subtitle
  doc.setFontSize(10)
  doc.text(`Ticket: ${ticket.id}`, x + 115, y + 15)

  y += 25

  // Table ticket
  doc.setFontSize(10)
  doc.setTextColor(0, 0, 0)

  // Table ticket
  doc.setFontSize(10)
  doc.setTextColor(0, 0, 0)

  for (const id of ticketsIds) {
    const ticketMessages = messages.filter(tm => tm.ticket_id === id)

    if (y + 5 > 280) {
      doc.addPage()
      y = 10
    }

    doc.text(`Ticket: ${id}`, x + 85, y + 5)

    y += 10

    if (ticketMessages) {
      autoTable(doc, {
        head: [['Data', 'Remetente', 'Mensagem']],
        body: ticketMessages.map(message => [format(new Date(message.ticketLastMessage ? message.ticketLastMessage : message.created_at), 'dd/MM/yyyy HH:mm:ss'), message.from.length > 18 ? getAttendantName(message.from) : message.from, message.content.length > 0 ? message.content : 'Anexo']),
        startY: y,
        theme: 'grid'
      })
    } else {
      continue
    }

    y = doc.lastAutoTable.finalY + 10
  }

  y = doc.lastAutoTable.finalY + 10

  // Footer
  setFooters(doc, x, y)

  doc.setProperties({
    title: `Ticket ${ticket.id}`,
    subject: `Ticket ${ticket.id}`
  })

  doc.save(`ticket-${ticket.id}.pdf`)
}

function getAttendantName (id) {
  const allAttendants = store.getters.allAttendants

  if (allAttendants.length) {
    const attendantObj = allAttendants.find(a => a.id === id)

    if (attendantObj) {
      return attendantObj.name
    }
  }
}

const logoImg = () => {
  const img = new Image()
  img.src = `${apiUri}/appearance/logo`
  return img
}

/**
 * @param {jsPDF} doc - The jsPDF document object.
 * @param {number} x - The x coordinate.
 * @param {number} y - The y coordinate.
 */
const setFooters = (doc, x, y) => {
  doc.setFontSize(10)
  doc.setTextColor(0, 0, 0)
  const totalPages = doc.internal.getNumberOfPages()

  for (let i = 1; i <= totalPages; i++) {
    doc.setPage(i)
    doc.text(store.getters.getAppName, 105, 292, { align: 'center' })
    doc.text(`Página ${i} de ${doc.internal.getNumberOfPages()}`, 180, 292)
  }
}
